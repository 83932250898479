a.fixed-download-button {
    background: black;
    display: flex;
    text-decoration: none;
    color: white;
    justify-content: center;
    align-items: center;
    width: 130px;
    border-radius: 10px;
    height: 42px;
}

a.fixed-download-button:hover {
    background: rgb(81, 81, 81);
}

a.fixed-download-button > img {
    width: 17px;
    margin-right: 10px;
}

a.fixed-download-button > img.fixed-ios {
    margin-bottom: 2px;
}

a.fixed-download-button > div.fixed-download-text {
    font-size: 15px;
    /* margin-top: 2px; */
}

div.broadcast-download-container div,  
div.broadcast-download-container p{
    font-family: "Noto Sans KR";
    color: white;
}

div.broadcast-download-container p.broadcast-download-subtitle {
    color: rgb(255 255 255 / 75%);
}