:root {
  --thehd-wht: url(./assets/thehyundaiEmoji/thehd-wht.svg);
  --thehd-blk: url(./assets/thehyundaiEmoji/thehd-blk.svg);
  --heart-pk: url(./assets/thehyundaiEmoji/heart-pk.svg);
  --heart-wht: url(./assets/thehyundaiEmoji/heart-wht.svg);
  --candy-yg: url(./assets/thehyundaiEmoji/candy-yg.svg);
  --candy-pp: url(./assets/thehyundaiEmoji/candy-pp.svg);
  --footer-left-size: 75vw;
  --item-title-size: 14px;
  --item-price-size: 16px;
  --btn-new-chat-btn: 13px;
}

@media (max-width: 350px) {
  :root {
    --footer-left-size: 70vw;
    --item-title-size: 11px;
    --item-price-size: 13px;
    --btn-new-chat-btn: 11px;
  }
}

img {
  user-select: none !important;
  -webkit-user-drag: none;
}

.chat-bg-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.chat-bg-transparent {
  background: rgba(0, 0, 0, 0);
}
