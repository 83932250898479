#thd-chat-root div,
#thd-chat-root span {
  font-family: "noto sans KR";
}

.thd-chat-root {
  padding-bottom: 0px;
  z-index: 900;
  width: 85%;
}

.thd-gradient-box {
  mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
  -webkit-mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
}

.thd-chat-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.thd-chat-list::-webkit-scrollbar {
  display: none;
}

.btn-new-chat-hd {
  position: absolute;
  bottom: 5px;
  right: 25%;
  z-index: 901;
}
