/* css에 삽입하는 방법 */
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
}

div,
span {
  font-family: "NanumBarunGothic";
}
a.nav-link {
  text-decoration: none;
  color: black;
}

a.activated > div {
  background-color: rgb(158, 158, 158);
  color: white;
}

a.activated > div:hover {
  background-color: rgb(124, 124, 124);
  color: white;
}

.no-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.display-none {
  display: none;
}

.color-snow {
  color: snow;
}

.color-orangered {
  color: orangered;
}

.color-dark {
  color: rgb(72, 68, 66);
}

.live-thumbnail {
  border-radius: 10px;
}

.chat-list-body {
  text-align: left;
  display: flex;
}

amplify-authenticator {
  --container-height: 100%;
  --container-align: center;
  --container-justify: center;
}

.amplify-button {
  font-family: "Happiness-Sans" !important;
}

.amplify-input {
  font-family: "Happiness-Sans" !important;
}

.amplify-tabs-item {
  font-family: "Happiness-Sans" !important;
}

.amplify-lable {
  font-family: "Happiness-Sans" !important;
}

.amplify-heading {
  font-family: "Happiness-Sans" !important;
}

.login-font {
  font-family: "Happiness-Sans" !important;
  font-weight: 400;
}

.client-channel-datePicker-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.svc-icon {
  vertical-align: middle;
}

.svc-text {
  vertical-align: middle;
  text-align: center;
}

.font-small {
  font-size: small;
}

.white-space {
  margin-left: 5px;
}

.viewer-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  height: 100%;
  z-index: 901;
}

.small-layout {
  overflow: auto;
  font-size: 0;
  text-align: center;
}

.small-layout-inner {
  display: inline-block;
  width: 100%;
  max-width: 56.25vh;
  /* min-width: 360px;
  min-height: 640px; */
  text-align: left;
}

.viewer-root-frame {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  height: 100%;
  z-index: 901;
}

.share-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 901;
}

.Live-Layout-wrapper {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.Live-Layout-wrapper-frame {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.Live-Layout-inner {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.Live-Swipe-content {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
}

.VideoPlayer-wrapper {
  position: relative;
  height: 100%;
  overscroll-behavior: contain;
  overflow: hidden;
  z-index: 902;
}

.VideoPlayer-wrapper-admin {
  position: relative;
  height: 100%;
  overscroll-behavior: contain;
  overflow: hidden;
  z-index: 600;
}

.live-header-root {
  width: 100%;
}

.live-header-wrapper {
  width: 100%;
  /* padding: 20px; */
  /* 2023.09.22 앱 배포 전엔 주석 처리 */
  background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  z-index: 997;
}

.live-header-padding {
  width: 100%;
  padding: 20px;
}

.vod-select {
  padding: 2px 10px;
  box-sizing: border-box;
  background-color: transparent;
  border: solid 2px;
  border-color: snow;
  border-radius: 20px;
  transition: all 1s;
}

.Tap-screen-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 903;
}

.Tap-screen-wrapper-50 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50px;
  left: 0;
  z-index: 903;
}

.bottom-50 {
  bottom: 50px !important;
}

.Tap-screen-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.live-content-root {
  margin-top: auto;
}

.live-content-wrapper {
  margin-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.Dimmed {
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.live-content-chat {
  display: flex;
  align-items: flex-end;
  margin-bottom: 3px;
}

.live-content-chat-mobile {
  padding: 0 14px;
}

.live-content-notice {
  position: relative;
  padding: 0px 14px;
}

.live-content-footer {
  margin-bottom: calc(-1 * constant(safe-area-inset-bottom));
  margin-bottom: calc(-1 * env(safe-area-inset-bottom));
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  position: relative;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding: 8px 14px;
}

div.live-content-footer-ios-mb {
  margin-bottom: 26px;
}

.live-content-th-absolute {
  margin-bottom: calc(-1 * constant(safe-area-inset-bottom));
  margin-bottom: calc(-1 * env(safe-area-inset-bottom));
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.live-content-footer-right {
  position: absolute;
  right: 16px;
  bottom: 24px;
  z-index: 101;
}

.like-count {
  box-sizing: border-box;
  background-color: rgba(20, 20, 20, 0.6);
  border-radius: 20px;
  font-weight: bold;
}

.live-user-comming-alert {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: 10px;
}

.live-user-comming-alert-wrapper {
  margin-right: auto;
}

.live-alert-box {
  padding: 6px 15px;
  box-sizing: border-box;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.live-alert-box div {
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-size: 13px;
}

.live-user-comming-alert-body {
  /* background-color: rgba(14, 143, 91, 0.4);
  border-radius: 20px; */
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

.live-concurrent-view-body {
  background: rgba(255, 55, 109, 0.5);
  backdrop-filter: blur(5px);
}

.live-coupon-root {
  display: flex;
  flex-direction: row-reverse;
}

.test-text-area {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex: 1 1 auto;
  height: 23px;
  padding: 12px 5px 13px 17px;
  border: 0;
  background-color: #fff;
  line-height: 1.3;
  font-size: 15px;
  color: #232325;
  letter-spacing: -0.03em;
  caret-color: #923dff;
  height: 20px !important;
}

.live-main-root {
  min-width: 300px;
  overscroll-behavior: contain;
  overflow: hidden;
}

.live-menu-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 901;
}

.live-menu-wapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.live-menu-contents {
  margin-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.channel-info-root {
  position: relative;
  height: auto;
}

.channel-info-tab {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  word-break: break-all;
  white-space: pre-line;
  z-index: 999;
}

.channel-info-chat {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60vh;
  z-index: 1;
}

.css_bottom {
  position: absolute;
  bottom: 0px;
  visibility: hidden;
}

.btn-save-myaccount-box {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #ed4166;
}

.login-wrapper {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 901;
}

.login-alert {
  position: absolute;
  width: 90%;
  top: 65px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 901;
}

.quote-Container {
  padding: 5px 19px 5px 19px;
  box-sizing: border-box;
  background-color: #bcc8d4;
  border-radius: 20px;
  min-height: 50px;
  margin-top: 10px;
}

.quote-Container-2 {
  position: absolute;
  bottom: -23px;
  padding: 5px 19px 5px 19px;
  box-sizing: border-box;
  background-color: #bcc8d4;
  color: black;
  border-radius: 20px;
  min-height: 50px;
  margin-top: 10px;
}

.quote-Message {
  padding: 0 14px;
}

.quote-icon {
  padding-left: 14px;
}

#input-Container {
  padding: 5px;
  box-sizing: border-box;
  background-color: #b9c4cf;
  border-radius: 20px;
  height: 34px;
}

#input-Container fieldset {
  border: hidden;
}

#input-Message {
  padding: 0.5px 14px;
}

#input-chat {
  padding: 5px;
  box-sizing: border-box;
  background-color: transparent;
  border: solid 1px;
  border-color: snow;
  border-radius: 20px;
  width: 70%;
  height: 34px;
  margin: auto 20px;
}

#input-chat fieldset {
  border: hidden;
}

#iinput-chat-Message {
  color: snow;
}

.datatable {
  height: 500px;
  width: "100%";
}

.flex {
  display: flex;
}

.flex-c {
  display: flex;
  flex-direction: column;
}

.fl-ed {
  justify-content: flex-end;
}

.sp-bt {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}

.bt-md {
  height: 40px;
}

.topMg-10 {
  margin-top: 10px;
}

.bottomMg-10 {
  margin-bottom: 10px;
}

.rightMg-10 {
  margin-right: 10px;
}

.pd-0-imp {
  padding: 0px !important;
}

.pd-0 {
  padding: 0px;
}

.pd-40 {
  padding: 40px;
}

.pd-100-h {
  padding-left: 100px;
  padding-right: 100px;
}

.errorMsg {
  width: 92%;
  font-size: 15px;
  color: red;
}

.create-channel-box {
  min-width: 520px;
}

.edit-channel-Container {
  min-width: 520px;
}

.edit-channel-box .MuiTextField-root {
  margin: 8px;
  width: 50ch;
  min-width: 50ch;
}

#video-player {
  min-width: 100%;
  max-width: 100%;
  background-color: black;
}

.aspect-16x9 {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  border-radius: 10px;
  overflow: hidden;
}

.pos-relative {
  position: relative;
}

.video-cont {
  width: 50%;
  height: 50%;
}

.record_preview {
  width: 100%;
  height: 100%;
}

.live_preview {
  width: 100%;
  height: 100%;
}

.record-preview-drawer {
  padding: 16px;
}

.live-preview-drawer {
  padding: 16px;
}

.live_preview_btn {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 5px;
  z-index: 850;
}

.record_preview_btn {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 5px;
  z-index: 850;
}

.player-ui {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.player-ui-actions {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 860;
  padding: 0 20px 10px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.player-video-el {
  width: 100%;
  height: 100%;
}

.player-ui-button {
  display: block;
  user-select: none;
  outline: none;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
}

.like-wrapper {
  position: relative;
  user-select: none;
  overflow-x: none;
}

.like-button {
  display: block;
  outline: none;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
}

.heart-outer {
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 990;
}

.heart-inner {
  position: relative;
  margin: 0 10px;
  width: 30px;
  height: 30px;
  display: inline-block;
  transform: rotate(-45deg);
}

.heart-inner::before,
.heart-inner::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: inherit;
}

.heart-inner::before {
  top: -15px;
  left: 0;
}

.heart-inner::after {
  top: 0;
  left: 15px;
}

.smile-outer {
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 990;
}

.smile-inner {
  position: relative;
  margin: 0 10px;
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(./assets/icons/smileEmoji.svg) no-repeat top left;
  background-size: contain;
}

.smile-inner::before,
.smile-inner::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background: url(./assets/icons/smileEmoji.svg) no-repeat top left;
  background-size: contain;
}

.thehyundai-outer {
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 990;
}

.thehyundai-inner {
  position: relative;
  margin: 0 10px;
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(./assets/icons/thehyundaiSvg.svg) no-repeat top left;
  background-size: contain;
}

.thehyundai-inner::before,
.thehyundai-inner::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background: url(./assets/icons/thehyundaiSvg.svg) no-repeat top left;
  background-size: contain;
}

.gift-outer {
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 990;
}

.wow-layer {
  background-color: rgba(75, 75, 75, 0.582);
  font-size: 30px;
  padding: 10px 30px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.settings-menu {
  background-color: rgba(75, 75, 75, 0.582);
  /* padding: 10px; */
  position: absolute;
  right: 5px;
  bottom: 40px;
  /* transform: translate( -50%, -50% ); */
}

.settingMenuItem {
  color: white;
  cursor: pointer;
  padding: 10px 5px;
}

.settingMenuItem:hover {
  background-color: rgba(124, 124, 124, 0.582);
}

.player-play-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100%;
    height: 100%; */
  background: #80808000;
  z-index: 10;
}

.player-play-layout:hover {
  background: rgba(77, 77, 77, 0.26);
}

.player-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  z-index: 999;
}

.vod-screen-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
  bottom: 0px;
  right: 0px;
  z-index: 801;
}

.loading-circle-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.width100 {
  width: 100%;
}

.live-footer {
  width: 100%;
  padding: 10px;
}

.template-right-footer {
  position: absolute;
  padding: 20px;
  padding-bottom: 20px;
  bottom: 0px;
  right: 0px;
  z-index: 810;
}

.live-left-footer {
  position: absolute;
  padding: 10px;
  padding-bottom: 20px;
  bottom: 0px;
  left: 0px;
  z-index: 810;
}

.live-right-footer {
  position: absolute;
  padding: 20px;
  padding-bottom: 20px;
  bottom: 0px;
  right: 0px;
  z-index: 810;
}

.live-right-footer-tmp {
  position: absolute;
  padding: 20px;
  margin-bottom: 60px;
  bottom: 0px;
  right: 0px;
  z-index: 810;
}

.vod-right-footer {
  position: absolute;
  padding: 20px;
  margin-bottom: 60px;
  bottom: 0px;
  right: 0px;
  z-index: 810;
}

.heart_root {
  position: relative;
  bottom: 0;
  right: 0px;
}
.heart-actions {
  position: absolute;
  bottom: 0;
  right: 0px;
  z-index: 860;
  padding: 0 20px 10px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100px;
  height: 300px;
  transform: translateX(10px);
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-17 {
  margin-bottom: 17px;
}

.test-text {
  position: absolute;
  color: snow;
  z-index: 902;
}
.channel-manager-preview {
  position: relative;
}

.channel-manager-player {
  display: flex;
  width: 100%;
  height: 100%;
}

.channel-manager-player-frame {
  border: 0;
  width: 100%;
  height: 100%;
}

.live-channel-info-mobile-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.live-log {
  position: absolute;
  top: 100px;
  left: 10px;
  z-index: 999;
}

.chart-root {
  width: 100%;
  height: 100%;
}

.statistic-chart-root {
  width: 100%;
  height: 176px;
}

.live-statistic-info-mobile-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2d2d2d5e;
  border-radius: 13px;
  padding: 5px 5px 2px;
}

.live-statistic-info-mobile-box p {
  font-size: 0.75rem;
}

.live-content-game {
  position: relative;
  padding: 0px 14px;
}

.white-shadow {
  filter: drop-shadow(0px 0px 1px #494949);
}

.thd-live-no-shadow {
  text-shadow: none !important;
  filter: none !important;
}

.thd-live-title-two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-shadow {
  text-shadow: 0 0 #232325;
}

.live-game-draw-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #00000099;
  width: 100%;
  height: 100%;
  z-index: 901;
}

.zindex100 {
  z-index: 100;
}

.live-channel-info-pc-chat {
  height: 91%;
}

.live-channel-info-chat-box {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.05);
}

#live-channel-info-input-chat {
  padding: 20px 10px;
  height: 41px;
  background-color: #ffffff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

#live-channel-info-input-chat fieldset {
  border: hidden;
}

.live-channel-info-btn-new-chat {
  position: absolute;
  bottom: 5px;
  right: 37%;
  z-index: 901;
}

.speech-bubble {
  position: relative;
  padding: 10px;
  background: #2e2f34;
  border-radius: 0.4em;
  color: #fff;
  max-width: 100%;
  white-space: pre-wrap;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #2e2f34;
  border-left: 0;
  border-top: 0;
  margin-top: -15.5px;
  margin-left: -10px;
}

.speech-bubble-me {
  position: relative;
  padding: 10px;
  background: #024d5e;
  border-radius: 0.4em;
  color: #fff;
  max-width: 100%;
  white-space: pre-wrap;
}

.speech-bubble-me:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #024d5e;
  border-right: 0;
  border-top: 0;
  margin-top: -15.5px;
  margin-right: -10px;
}

.frame-root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* 모바일 어드민 채팅 */
.live-channel-info-mobile-fullscreen .speech-bubble {
  background: #e3e3e3;
  color: #000000;
}

.live-channel-info-mobile-fullscreen .speech-bubble:after {
  border-right-color: #e3e3e3;
}

.live-channel-info-mobile-fullscreen .speech-bubble-me {
  background: #fae706;
  color: #000000;
}

.live-channel-info-mobile-fullscreen .speech-bubble-me:after {
  border-left-color: #fae706;
}

.live-channel-info-mobile-fullscreen .quote-Container-2 {
  bottom: 41px;
  /* background-color: #bcc8d4; */
  border-radius: 0px;
}

.error-notice {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 1rem !important;
  font-weight: 700;
  z-index: 901;
  color: snow;
  white-space: pre-wrap;
  text-align: center;
  line-height: 40px;
}

.video-poster {
  display: flex;
  width: 100%;
  height: 100%;
}
