.thd-live-qna-drawer-top {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.thd-live-qna-drawer-top-img {
  width: 20px;
  height: 20px;
}
.thd-live-qna-drawer-close {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}
.thd-live-qna-drawer-top-text {
  width: 42px;
  height: 28px;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans KR";
  line-height: 28px;
  color: #111111;
  margin-left: 6px;
}
.thd-live-qna-list {
  width: 100%;
  height: 52vh;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.thd-live-qna-list::-webkit-scrollbar {
  display: none;
}
.thd-question-icon {
  position: relative;
  width: 18px;
  height: 18px;
  top: 10px;
}
.thd-question-item {
  position: relative;
  left: 8px;
}

.thd-answer-list-item {
  position: relative;
  background-color: #f5f5f5;
  padding: 11px 16px;
  border-radius: 10px;
  margin-bottom: 30px;
}
.thd-live-qna-more {
  padding-bottom: 20px;
  color: #777777;
}
