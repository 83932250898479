.thd-coupon-complete-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 180px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px,
    rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  border: 0;
  border-radius: 20px;
  padding: 0;
  flex: 1 1 4 1;
}

.thd-coupon-complete-modal-content {
  padding: 15px 15px;
}

.thd-coupon-complete-header-blank {
  position: relative;
  width: 100%;
  height: 100%;
}
.thd-coupon-complete-content-blank {
  position: relative;
  width: 100%;
  height: 10px;
}

.thd-coupon-complete-modal-close {
  right: 5px;
}

.thd-coupon-complete-icon {
  position: relative;
  width: 27px;
  height: 18px;
}

.thd-coupon-complete-check-icon {
  position: relative;
  width: 30px;
  height: 30px;
}
.thd-coupon-complete-text {
  white-space: nowrap;
}
