.remain-item {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 902;
}

.remain-item div {
  font-family: "Noto Sans KR";
}

.remain-live-mark img {
  width: 11vmin;
  height: 11vmin;
}

.remain-comming-soon {
  margin-top: 6px;
  font-family: "Noto Sans KR";
  font-weight: 900;
  font-size: 5vh;
  line-height: 1em;
}

.remain-broad-time {
  margin-top: 6px;
  font-weight: 400;
  font-size: 3vh;
  /* text-shadow: rgb(0 0 0 / 27%) 1px 1px; */
  line-height: 1em;
}

.remain-time {
  margin-top: 15px;
  box-sizing: border-box;
  background-color: #ff376d;
  border-radius: 27px;
  padding: 3px 4vmin;
  display: flex;
  align-items: center;
  font-size: 1.5vh;
  font-weight: 700;
}
