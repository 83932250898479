/* background 변수는 src/thdstyle.css에 있음 */
:root {
  --hd-size: 30px;
  --emoji-size: 20px;
}
/* 흰색 현대백화점 로고 */
.thehd-wht-outer {
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 990;
}
.thehd-wht-inner {
  position: relative;
  margin: 0 10px;
  width: var(--hd-size);
  height: var(--hd-size);
  display: inline-block;
  background: var(--thehd-wht) no-repeat top left;
  background-size: contain;
}
.thehd-wht::before,
.thehd-wht::after {
  content: "";
  position: absolute;
  width: var(--hd-size);
  height: var(--hd-size);
  background: var(--thehd-wht) no-repeat top left;
  background-size: contain;
}
/* 검은색 현대백화점 로고 */
.thehd-blk-outer {
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 990;
}
.thehd-blk-inner {
  position: relative;
  margin: 0 10px;
  width: var(--hd-size);
  height: var(--hd-size);
  display: inline-block;
  background: var(--thehd-blk) no-repeat top left;
  background-size: contain;
}
.thehd-blk-inner::before,
.thehd-blk-inner::after {
  content: "";
  position: absolute;
  width: var(--hd-size);
  height: var(--hd-size);
  background: var(--thehd-blk) no-repeat top left;
  background-size: contain;
}
/* 핑크색 하트 로고 */
.heart-pk-outer {
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 990;
}
.heart-pk-inner {
  position: relative;
  margin: 0 10px;
  width: var(--emoji-size);
  height: var(--emoji-size);
  display: inline-block;
  background: var(--heart-pk) no-repeat top left;
  background-size: contain;
}
.heart-pk-inner::before,
.heart-pk-inner::after {
  content: "";
  position: absolute;
  width: var(--emoji-size);
  height: var(--emoji-size);
  background: var(--heart-pk) no-repeat top left;
  background-size: contain;
}
/* 흰색 하트 로고 */
.heart-wht-outer {
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 990;
}

.heart-wht-inner {
  position: relative;
  margin: 0 10px;
  width: var(--emoji-size);
  height: var(--emoji-size);
  display: inline-block;
  background: var(--heart-wht) no-repeat top left;
  background-size: contain;
}

.heart-wht-inner::before,
.heart-wht-inner::after {
  content: "";
  position: absolute;
  width: var(--emoji-size);
  height: var(--emoji-size);
  background: var(--heart-wht) no-repeat top left;
  background-size: contain;
}
/* 초록 캔디 로고 */
.candy-yg-outer {
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 990;
}

.candy-yg-inner {
  position: relative;
  margin: 0 10px;
  width: var(--emoji-size);
  height: var(--emoji-size);
  display: inline-block;
  background: var(--candy-yg) no-repeat top left;
  background-size: contain;
}

.candy-yg-inner::before,
.candy-yg-inner::after {
  content: "";
  position: absolute;
  width: var(--emoji-size);
  height: var(--emoji-size);
  background: var(--candy-yg) no-repeat top left;
  background-size: contain;
}
/* 보라색 캔디 로고 */
.candy-pp-outer {
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 990;
}

.candy-pp-inner {
  position: relative;
  margin: 0 10px;
  width: var(--emoji-size);
  height: var(--emoji-size);
  display: inline-block;
  background: var(--candy-pp) no-repeat top left;
  background-size: contain;
}

.candy-pp-inner::before,
.candy-pp-inner::after {
  content: "";
  position: absolute;
  width: var(--emoji-size);
  height: var(--emoji-size);
  background: var(--candy-pp) no-repeat top left;
  background-size: contain;
}

.gradient-emoji {
  mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
  -webkit-mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
}
