.th-client-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  min-width: 100%;
  max-width: 100%;
  background-color: black;
  object-fit: cover;
  z-index: 800;
  transition: all 1s;
}
.th-client-video iframe,
.th-client-video object,
.th-client-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.th-video-controls {
  right: 0;
  left: 0;
  position: absolute;
  bottom: 0;
  margin-right: 8px;
  transition: all 0.2s ease;
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)); */
  z-index: 801;
}

.th-video-controls.hide {
  opacity: 0;
  pointer-events: none;
}
