:root {
  --thd-green: #384e45;
  --thd-pink: #e6c6df;
}

.thd-modal-share {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  border: 0;
  padding: 0;
}

.thd-share-small-text {
  font-size: 0.8rem;
  text-align: center;
}

.thd-share-icon {
  margin-top: 6px;
  margin-bottom: auto;
  width: 44px;
  height: 44px;
}

.thd-share-title-icon {
  width: 20px;
  height: 24px;
}

.thd-share-title-text {
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans KR";
  line-height: 28px;
  color: #111111;
  margin-left: 6px;
}

.thd-modal-top-border {
  display: flex;
  padding: 0;
  height: 6px;
}

.thd-green-border {
  background-color: var(--thd-green);
  flex-basis: 35%;
}

.thd-pink-border {
  background-color: var(--thd-pink);
  flex-basis: 65%;
}

.thd-modal-content {
  padding: 20px;
}

.thd-stack-row {
  width: 100%;
}

.thd-item-small {
  width: 35px;
  height: 35px;
}

.thd-modal-close {
  position: absolute !important;
  top: 10px;
  right: 5px;
}
