#thd-input-chat {
  padding: 10px 5px;
  /* border-color: #dbdbdbb8; */
  height: 41px;
  box-sizing: border-box;
  /* position: fixed;
    bottom: 0;
    left: 0;
    right: 0; */
  background-color: #ffffff;
  filter: drop-shadow(2px 4px 6px #494949);
}

#thd-input-chat fieldset {
  border: hidden;
}

#thd-input-chat input#input-chat-Message {
  font-size: 0.8rem;
}

.thd-without-padding input {
  padding: 0px, 14px !important;
}

.thd-footer-right-icon {
  /* margin-top: 6px; */
  margin-top: 16px;
  /* margin-bottom: auto; */
  margin-bottom: 7px;
  /* width: 44px;
  height: 44px; */
  width: 25px;
  height: 25px;
  object-fit: contain;
}

#thd-input-chat-button {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ff376d;
}

.thd-chat-btn-icon {
  width: 30px;
  height: 27px;
  margin-bottom: 2px;
  margin-top: 18px;
}

.thd-chat-btn-title {
  font-size: 12px;
}

#divheartActions {
  position: absolute;
  bottom: 0;
  right: -40px;
  z-index: 860;
  padding: 0 20px 10px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 140px;
  height: 300px;
  transform: translateX(10px);
  mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
  -webkit-mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
  mask-image: linear-gradient(to left, rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
  -webkit-mask-image: linear-gradient(to left, rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
}
